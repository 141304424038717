<template>
  <div class="mx-3">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue" class="mb-3 mt-3" dark v-bind="attrs" v-on="on">
          {{ $t('Add New Invoice') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="itemClicked(item)" style="cursor: pointer" v-for="item in items" :key="item.id">
          <v-list-item-title>{{ $t(invoiceTypeFormatter(item)) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-row>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" v-model="filters.serial" :label="$t('Invoice Number')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.invoice_type_name" :label="$t('Invoice Name')" dense item-text="name"
          item-value="name" outlined :items="items" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.invoice_type" :label="$t('Invoice Type')" dense item-text="invoiceArLabel"
          item-value="type" outlined :items="items" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.invoice_state" :label="$t('Invoice State')" dense item-text="name" item-value="value"
          outlined :items="stateInvoice" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" v-model="filters.client_name" :label="$t('Client Name')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" v-model="filters.paid_amount" type="number" :label="$t('Paid Value')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" v-model="filters.total_amount" type="number" :label="$t('Total Value')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" v-model="filters.notes" :label="$t('notes')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 py-5" hide-default-footer :items-per-page="10">
      <template v-slot:item.actions="{ item }">
        <td style="width: 10%;">
          <v-btn @click="invoiceTypeToHandler(item)" color="blue" icon>
            <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
          <deleteInvoice :id="item.id" @record-deleted="getAll" />
        </td>
      </template>
    </v-data-table>
    <v-pagination v-if="!loading && items.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>

<script>
import axios from "axios";
import deleteInvoice from "./deleteInvoice.vue";
import { saveAs } from 'file-saver';
import i18n from "../../../../i18n";
export default {
  components: { deleteInvoice },
  data: () => ({
    stateInvoice: [
      {
        name: i18n.t('Locked'),
        value: 1,
      },
      {
        name: i18n.t('UnLocked'),
        value: 2,
      }
    ],
    types: [
      {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('sales'),
        value: "SALES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
      {
        label: i18n.t('sales refunds'),
        value: "SALES_REFUNDS",
      },
      {
        label: i18n.t('transportation'),
        value: "TRANSPORTATION",
      },
    ],
    tableItems: [],
    invoiceTypes: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t('id'), value: "id" },
      { text: i18n.t('Invoice Number'), value: "serial" },
      { text: i18n.t('Invoice Date'), value: "date" },
      { text: i18n.t('Invoice Name'), value: "invoiceTypeName" },
      { text: i18n.t('Invoice Type'), value: "type" },
      { text: i18n.t('Client Name'), value: "clientAccountName" },
      { text: i18n.t('Paid Value'), value: "paid_amount" },
      { text: i18n.t('Total Value'), value: "total_amount" },
      { text: i18n.t('notes'), value: "notes" },
      { text: i18n.t('Invoice Stat'), value: "invoiceStatus" },
      { text: "", value: "actions" },
    ],
    items: [],
    filters: {
      id: null,
      serial: null,
      invoice_type_name: null,
      invoice_state: null,
      invoice_type: null,
      client_name: null,
      paid_amount: null,
      total_amount: null,
      notes: null,
    },
  }),
  methods: {
    clearFilter() {
      this.filters.id = undefined;
      this.filters.serial = undefined;
      this.filters.invoice_state = undefined;
      this.filters.invoice_type_name = undefined;
      this.filters.invoice_type = undefined;
      this.filters.client_name = undefined;
      this.filters.paid_amount = undefined;
      this.filters.total_amount = undefined;
      this.filters.notes = undefined;
      this.page = 1;
      this.getAll();
    },
    async exportToExcel(queryParams) {

      try {

        const excel = await axios.get(`/inventories/invoices/export`, {
          params: {
            ...queryParams,

          },
          responseType: 'blob'
        });
        saveAs(excel.data)


      } catch (err) {
        console.log('err', err);
      } finally {

      }

    },
    invoiceTypeToHandler(item) {
      if (item.invoice_type.type == "TRANSPORTATION") {
        this.$router.push(
          `edit-transportation-invoice/${item.id}?invoice_id=${item.invoice_type_id}`
        );
      } else {
        this.$router.push(
          `edit-invoice/${item.id}?invoice_id=${item.invoice_type_id}`
        );
      }
    },
    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/inventories/invoices/index", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        this.tableItems = res.data.data.map((el) => {
          const typeTemp = this.types.find(
            (typ) => el?.invoice_type?.type == typ.value
          );
          return {
            ...el,
            invoiceTypeName: el?.invoice_type?.name,
            clientAccountName: el?.client_account?.name,
            type: typeTemp ? typeTemp.label : " ",
            invoiceStatus: el.is_locked ? "Locked" : "UnLocked",
          };
        });

        this.total = res.data.meta.total;
      } finally {
        this.loading = false;
      }
    },
    invoiceTypeFormatter(invoiceType) {
      const invoiceTemp = [
        {
          label: "purchase",
          value: "PURCHASES",
        },
        {
          label: "sales",
          value: "SALES",
        },
        {
          label: "purchase refunds",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "sales refunds",
          value: "SALES_REFUNDS",
        },
        {
          label: "transportation",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == invoiceType?.type);
      let str = "";
      if (invoiceTemp) {
        str = ` ${invoiceType.name}`;
      }
      return str;
    },
    itemClicked(v) {
      if (v.type == "TRANSPORTATION") {
        this.$router.push(`/new-transportation-invoice?invoice_id=${v.id}`);
      } else {
        this.$router.push(`/new-invoice?invoice_id=${v.id}`);
      }
    },
    invoiceTypeLabelFormatter(type) {
      return [
        {
          label: "purchase",
          value: "PURCHASES",
        },
        {
          label: "sales",
          value: "SALES",
        },
        {
          label: "purchase refunds",
          value: "PURCHASE_REFUNDS",
        },
        {
          label: "sales refunds",
          value: "SALES_REFUNDS",
        },
        {
          label: "transportation",
          value: "TRANSPORTATION",
        },
      ].find((el) => el.value == type).label;
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.items = res.data.data.map((el) => ({
        ...el,
        invoiceArLabel: this.invoiceTypeLabelFormatter(el.type),
      }));
      console.log(this.items);
    },
  },
  created() {

    this.getInvoiceTypes();
  },
};
</script>

<style></style>
